import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import {CommonUtil} from "../../utils/CommonUtil";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import orgUserStatucRenderer from "../Organization/OrgUserStatucRenderer";
import Confirm from "../../Components/confirm";
import $ from "jquery";
import {Constants} from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import PhoneInput from "react-phone-input-2";
import {Events} from "../Languages/Events";

var userMode:string = "";
let userObj:any = null;
const Contacts = ()=>{

    const [users,setUsers] = useStateWithCallback([],()=>{
        if(userGridRef1.current){
            userGridRef1.current.columnFittoSize();
        }
    });
    const [showUserPopup,setShowUserPopup] = useState(false);
    const [showUserStatusPopup,setShowUerStatusPopup] = useState(false);
    const userGridRef1 = useRef<IAGrid>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);



    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const contactNoRef = useRef<HTMLInputElement>(null);
    const statusRef = useRef<HTMLSelectElement>(null);

    const [firstName,setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [email,setEmailId] = useState("");
    const [contactNumber,setContactNumber] = useState("");

    const [departments,setDepartments] = useState(CommonUtil.onlyAdminDepartment);
    const [depObj,setDepObj] = useState<any>(null);

    const [statues,setStatues] = useState(CommonUtil.statuses);
    const [statusObj,setStatusObj]  = useState<any>(null);
    const oprInterchange = useRef<HTMLInputElement>(null)
    const [interchangeChecked, setInterchangeChecked] = useState(false)
    const [depClass,setDepClass] = useState("mandatory");
    const [statusClass,setStatusClass] = useState("mandatory");
    const [userStatus,setUserStatus] = useState("");
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const { isMobile } = DetectDevice();
    const [userColumns] = useState([
        { field: 'loginId', headerName:CommonUtil.getMessageText(Constants.EMAIL_ID,"Email Id"),resizable:true,flex:1,width:100,sortable:true,tooltip: (params:any) => params.value,
            cellRenderer: orgUserRenderer,filter: 'agTextColumnFilter'},
        { field: 'firstName', headerName:CommonUtil.getMessageText(Constants.FIRST_NAME,'First Name'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'lastName', headerName:CommonUtil.getMessageText(Constants.LAST_NAME,'Last Name'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'phone', headerName:CommonUtil.getMessageText(Constants.CONTACT_NO,'Contact Number'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'department', headerName:CommonUtil.getMessageText(Constants.DEPARTMENT,'Department'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'status', headerName:CommonUtil.getMessageText(Constants.STATUS,'Status'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter',cellRenderer: orgUserStatucRenderer,},
    ]);

    let onClickUserDetails = (userData:any)=>{
        console.log(userData);
        //console.log(userGridRef.current?.getSelectedRows());
        userMode = CommonUtil.UPDATE_MODE;
        userObj = userData;
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmailId(userData.loginId);
        setContactNumber(userData.phone);
        setInterchangeChecked((userData.accessInterchangeMobileApplication) == "Y"?true:false);
        // setDepObj({Key:userData.department,Value:userData.department});
        try{
            setDepObj(departments[0]);
        }catch(e){console.log("departments not found")}
        setStatusObj({Key:userData.status,Value:userData.status});
        setShowUserPopup(true);
    }

    let onClickActiveUser = (userData:any)=>{
        //console.log(userData);
        if(userData.status == CommonUtil.ACTIVE || userData.status == CommonUtil.IN_ACTIVE){
            userObj = userData;
            setUserStatus(userObj.status);
            setTimeout(()=>{
                setShowUerStatusPopup(true);
            })
        }
    }

    let onClickUserStatus = ()=>{
       // console.log(userObj);
        let restObj:any = {};
        restObj.department = userObj.department;
        restObj.firstName = userObj.firstName;
        restObj.language = userObj.language;
        restObj.lastName = userObj.lastName;
        restObj.loginId = userObj.loginId;
        restObj.roleName = userObj.roleName;
        restObj.phone = userObj.phone;
        restObj.userId = userObj.userId;
        restObj.status = userObj.status == CommonUtil.ACTIVE?CommonUtil.IN_ACTIVE:CommonUtil.ACTIVE;

        let changeStatusAPI:string = "api/organization/changeUserStatus?saasOrgId="+localStorage.getItem("saasorgId");
        NetworkManager.sendJsonRequest(changeStatusAPI,restObj,"","PUT",true,onChangeUserStatus,onLoginError);
    }

    let onChangeUserStatus = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setShowUerStatusPopup(false);
                let strUserStatus = CommonUtil.getMessageText(Constants.USER_STATUS_UPDATED,"User Status Updated Successfully");
                ToastManager.showToast(strUserStatus,"Info");
                getAllUsers();
            }
        }
    }

    let onClickResend = (userData:any)=>{
        userObj = userData;
        let strConfirm = CommonUtil.getMessageText(Constants.CONFIRM,"Confirm");
        let resendInvitation = CommonUtil.getMessageText(Constants.RESEND_INVITATION,"Do you want Resend Invitation?");
        Confirm.show(strConfirm, resendInvitation, [{label:"Yes", callback:function(){
                //console.log(userData);
                let restObj:any = {};
                restObj.department = userData.department;
                restObj.firstName = userData.firstName;
                restObj.language = userData.language;
                restObj.lastName = userData.lastName;
                restObj.loginId = userData.loginId;
                restObj.roleName = userData.roleName;
                restObj.phone = userData.phone;
                restObj.userId = userData.userId;

                let resendAPI:string = "api/organization/resendInvitation?saasOrgId="+CommonUtil.getSelectedOrgId();
                NetworkManager.sendJsonRequest(resendAPI,restObj,"","POST",true,onReInviteUser,onLoginError);

            }}, {label:"No"}]);

    }

    let onChangeInterchange = (e: any) => {
        setInterchangeChecked(e.currentTarget.checked)
        //onChangeOprBorder();
    }
    let onReInviteUser = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let resendInvitation = CommonUtil.getMessageText(Constants.RESEND_SUCCESS,"Resend Invitation Successfully");
                ToastManager.showToast(resendInvitation,"Info");
            }
        }
    }

    let onCloseUserStausPopup = ()=>{
        setShowUerStatusPopup(false);
    }

    let onClickAddUser = ()=>{
        clearUserData();
        userMode = CommonUtil.CREATE_MODE;
        setShowUserPopup(true);
    }
    let onCloseUserPopup = ()=>{
        setShowUserPopup(false);
    }

    let onDepChange = (data:any)=>{
        setDepObj(data);
        (data)?setDepClass("select-box-valid"): setDepClass("select-box-invalid");
    }
    let onStatusChange = (data:any)=>{
        setStatusObj(data);
        (data)? setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");
    }

    let onCreateUser = ()=>{
        var isFn:boolean = CommonUtil.isMandatory(fnRef);
        var isLN:boolean = CommonUtil.isMandatory(lnRef);
        var isContact:boolean = true;
        var isMail:boolean = CommonUtil.isEmailMandatory(emailRef);

        if (!contactNumber.trim() || contactNumber.length < 11) {
            isContact = false
            setPhoneClass('is-invalid')
        }

        (depObj)?setDepClass("select-box-valid"): setDepClass("select-box-invalid");
        (statusObj)?setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");

        if(isFn && isLN && isContact && isMail){
            var restObj:any = {};
            restObj.firstName = firstName;
            restObj.lastName = lastName;
            restObj.loginId = email;
            restObj.phone = contactNumber;
            restObj.department = depObj?depObj.Key:"";
            restObj.accessInterchangeMobileApplication = interchangeChecked?"Y":"N";
           // restObj.roleName = roleObj?roleObj.Value:"";
           // restObj.status = statusObj.Key?statusObj.Key:"";
            if(userMode == CommonUtil.UPDATE_MODE){
                if(userObj){
                    restObj.userId = userObj.userId;
                }
            }
            var userUrl = "";
            var method = "POST";
            if(userMode == CommonUtil.CREATE_MODE){
                userUrl = "api/organization/inviteUser?saasOrgId="+CommonUtil.getSaasOrgId();
            }else{
                method = "PUT";
                userUrl = "api/organization/editUser?saasOrgId="+CommonUtil.getSaasOrgId();
            }

            NetworkManager.sendJsonRequest(userUrl,restObj,"",method,true,onUserCreate,onLoginError);
        }
    }

    let onUserCreate = (data:any)=>{
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                onCloseUserPopup();
                let strSuccess = CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS,"User Created Successfully");
                ToastManager.showToast(strSuccess, "Info");
                getAllUsers();
            }
        }
    }

    let getAllUsers = ()=>{
        setUsers([]);
        var qParam = CommonUtil.getSaasOrgId();
        var orgUrl = "api/organization/users?saasOrgId="+qParam;
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
    }

    useEffect(()=>{
        getAllUsers();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    },[])
    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            Events.dispatchBreadCrumbEvent();
            //navigate("orgList1");
        }else{
            getAllUsers();
        }
        Events.dispatchBreadCrumbEvent();
    }

    let onOrgGetAllUsers = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    var users = [];
                    if(Array.isArray(restData.users)){
                        users = restData.users;
                    }else{
                        users.push(restData.users);
                    }
                    setUsers(users);
                    //setRowData(orgs);
                    setTimeout(()=>{
                        if(userGridRef1.current){
                            userGridRef1.current.columnFittoSize();
                        }
                        if(inputRef.current){
                            inputRef.current.focus();
                        }
                    },3000);
                }
            }
        }


    }

    let clearUserData = ()=>{
        setFirstName("");
        setLastName("");
        setEmailId("");
        setContactNumber("");
        setDepObj(null);
        setStatusObj(null);
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }

    const handleInputPhone = (e: any) => {
        setContactNumber(e);
    }

    return(
        <>
            <Modal  show={showUserStatusPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px","height":"200px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{(userStatus == CommonUtil.ACTIVE)?CommonUtil.getMessageText(Constants.INACTIVE_USER,"Inactivate User"):CommonUtil.getMessageText(Constants.ACTIVE_USER,"Activate User")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseUserStausPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            {
                                (userStatus == CommonUtil.ACTIVE)?<span className="dark-text fs-7">{CommonUtil.getMessageText(Constants.PREVENT_USER_APP,"This action will prevent the user from using the vHub Application")}</span>:
                                    <span className="dark-text fs-7">{CommonUtil.getMessageText(Constants.SHOW_USER_APP,"This action will allow the user to access the vHub Application")}</span>
                            }
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-warning waves-effect" data-bs-dismiss="modal"
                                onClick={onCloseUserStausPopup}>{CommonUtil.getMessageText(Constants.CLOSE,"Close")}</button>
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onClickUserStatus} >{(userStatus == CommonUtil.ACTIVE)?CommonUtil.getMessageText(Constants.BLOCK_USER,"Block User"):CommonUtil.getMessageText(Constants.ACTIVE_USER,"Activate")}</button>
                    </div>
                </div>

            </Modal>
            <Modal  show={showUserPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px","height":"500px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.INVITE_USER,"Invite User")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseUserPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIRST_NAME,"First Name")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <input ref={fnRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder={CommonUtil.getMessageText(Constants.FIRST_NAME,"First Name")}  aria-label="First Name" value={firstName}
                                               onChange={(e)=>{setFirstName(e.currentTarget.value);CommonUtil.isMandatory(fnRef);}} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LAST_NAME,"Last Name")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <input ref={lnRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Last Name" aria-label="Last Name" value={lastName}
                                               onChange={(e)=>{setLastName(e.currentTarget.value);CommonUtil.isMandatory(lnRef);}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <input ref={emailRef} type="email"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Enter Email" aria-label="Email" value={email}
                                               onChange={(e)=>{setEmailId(e.currentTarget.value);CommonUtil.isEmailMandatory(emailRef);}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CONTACT_NO,"Contact Number")}  <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <PhoneInput
                                            country={'us'}
                                            countryCodeEditable={false}
                                            containerClass={phoneClass}
                                            value={contactNumber}
                                            placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, "Enter Phone Number")}
                                            inputClass={`input-phone ${contactNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                            onChange={(e) => {
                                                handleInputPhone(e)
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                            }}
                                            disableCountryGuess={true}
                                            disableDropdown={true}
                                            disableSearchIcon={true}
                                        />
                                        {/* <input ref={contactNoRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Contact Number"  aria-label="Contact Number" value={contactNumber}
                                               onChange={(e)=>{setContactNumber(e.currentTarget.value);CommonUtil.isMandatory(contactNoRef);}} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="flex">
                                    <input
                                        ref={oprInterchange}
                                        type="checkbox"
                                        className="checkBox"
                                        id="chkOperateCanada"
                                        name="OperateCanada"
                                        value="OperateCanada"
                                        onChange={(e) => {
                                            onChangeInterchange(e)
                                        }}
                                        checked={interchangeChecked ? true : false}
                                    />&nbsp;
                                    <label className="" htmlFor="customCheck1">Access Interchange Mobile Application</label>
                                </div>
                            </div>


                            <div className="col-md-6 col-12" style={{display:"none"}}>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTMENT,"Department")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <SingleSelectComp  dataSource={departments} selectedValue={depObj} handleChange={onDepChange} value={"Key"} label={"Value"}
                                                           className={depClass} width={"100%"} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark"  onClick={onCloseUserPopup}>{CommonUtil.getMessageText(Constants.CLOSE,"Cancel")}</button>
                        <button type="button" className="btn btn-success" onClick={onCreateUser}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>
                    </div>
                </div>

            </Modal>
            <div ref={divRef}   className="container-fluid container-fluid-padding">
                        <br />
                <div className="row fs-7 pt-1">
                    {isMobile ? (
                            <MGrid id="CustOrgUserGrid"  cols={userColumns} rows={users} pageSize={10} addNew={true} tooltip={CommonUtil.getMessageText(Constants.ADD_USER,"Add User")}
                                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{onClickUserDetails,onClickActiveUser,onClickResend}} addNewRecord={onClickAddUser} />
                    ) : (
                        <div className="ag-theme-alpine gridHeight" style={{ height: "calc(100vh - 200px)", width: "100%",paddingLeft:"8px",paddingRight:"5px" }}>
                            <AGGrid id="CustOrgUserGrid"  cols={userColumns} rows={users} pageSize={500} addNew={true} tooltip={CommonUtil.getMessageText(Constants.ADD_USER,"Add User")}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{onClickUserDetails,onClickActiveUser,onClickResend}} addNewRecord={onClickAddUser} />
                        </div>
                    )}
                </div>
            </div>

        </>

    );
}

export default Contacts;
