const ErrorRender = ({errors}:any) => {
  return (
    <div className="normal-table error-table pt-3" style={{ height:'auto !important', maxHeight:400 }}>
        <h6>Errors </h6>
        <table className="Equipment-table table-striped table-hover table-bordered">
           <thead>
              <tr>
                 <th>Row Number</th>
                 <th>Error</th>
              </tr>
           </thead>
           <tbody>
            {errors.map((err:any, index:any)=>{
             return(
                    <tr>
                        <td>
                        {err.rowNum}
                        </td>
                        <td>
                        {err.errorMsg}
                        </td>
                    </tr>
                    )
                })}
           </tbody>
        </table>
    </div>
    )
}

export default ErrorRender